<template>
  <div v-if="articles?.length > 0" class="flex-column">
    <div class="flex-row">
      <div class="flex-column">
        <h1>Articles</h1>
        <p v-if="numberOfResults" class="results-number">
          {{ `${numberOfResults} résultat${numberOfResults > 1 ? 's' : ''}` }}
        </p>
      </div>
      <div class="more">Tous les articles</div>
    </div>
    <div class="flex-row x-scroll">
      <SwiperArrows v-model="swiper" size="small" which="left" />
      <SwiperWrapper v-model="swiper" :initial-slide="0" slides-per-view="auto">
        <SwiperSlide v-for="article of articles" :key="article.objectID">
          <NuxtLink :to="`/blog/${article.slug}`" class="result-item">
            <div class="image-container" @click="onArticleClick()">
              <NuxtImg
                v-if="article?.image?.url"
                provider="twicpics"
                :src="article.image.url"
                alt="image"
              />
            </div>
            <div class="flex-column">
              <h2 class="title">{{ article.title }}</h2>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="description" v-html="renderMarkdown(article.description).rendered" />
            </div>
          </NuxtLink>
        </SwiperSlide>
      </SwiperWrapper>
      <SwiperArrows v-model="swiper" size="small" which="right" />
    </div>
  </div>
  <div v-else-if="numberOfResults === 0" class="result-item">
    <h3>Aucun article trouvé</h3>
  </div>
</template>

<script lang="ts" setup>
import { AlgoliaArticle } from '@/lib/algolia-types/AlgoliaArticle';
import { SwiperBinding } from '@/lib/types/swiper';
import { renderMarkdown } from '@/lib/markdown';

defineProps<{
  articles: AlgoliaArticle[];
  numberOfResults: number;
}>();

const emit = defineEmits<{
  (eventSelectedCountry: 'articleClick'): void;
}>();

function onArticleClick() {
  emit('articleClick');
}

const swiper = ref<SwiperBinding>();
</script>

<style lang="scss" scoped>
@use '$/text-ellipsis.scss';
@use '$/colors.scss';
@use '$/misc.scss';

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .results-number {
    color: colors.$grey-700;
  }

  .title {
    @include text-ellipsis.single-line();

    font-size: 18px;
  }

  .description {
    @include text-ellipsis.multi-line(2);

    font-size: 14px;
  }
}

.x-scroll {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.result-item {
  display: flex;
  width: 400px;
}

.image-container {
  height: 100px;
  margin: auto;
  padding: 20px;
}

.more {
  @include misc.bold-link();

  justify-content: right;
  padding-top: 10px;
  text-align: right;
}
</style>
