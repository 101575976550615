<template>
  <div
    class="journey-card"
    :style="`background-image: url('${useRuntimeConfig().public.marcovasco.twicpicsPlanetveoUrl}${
      journey.titleImage
    }'); background-position:center`"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <div class="inner">
      <div class="top">
        <div class="left"></div>
        <!--  <div class="right">
          <Icon :name="like ? 'heart-active' : 'heart-inactive'" @click="like = !like" />
        </div>-->
      </div>

      <NuxtLink
        class="infos"
        :to="`/${journey.continents?.[0]?.slug}/${journey.countries?.[0]?.slug}/tours/${journey.slug}/`"
      >
        <h3 class="title">{{ journey.title }}</h3>
        <div v-if="journey.price" class="price">
          <div class="label">À partir de</div>
          <div class="value">{{ journey.price.toLocaleString() }}€*</div>
        </div>
      </NuxtLink>
    </div>

    <NuxtLink
      class="bottom"
      :to="`/${journey.continents?.[0]?.slug}/${journey.countries?.[0]?.slug}/tours/${journey.slug}/`"
    >
      <div class="durations">
        <!-- <div class="co2">
          {{ journey.attributes.CO2 }}
        </div> -->
        <div class="point" />

        <div v-show="!(journey.days <= 0)" class="days">
          {{ journey.days }} {{ journey.days === 1 ? 'Jour' : 'Jours' }}
        </div>
        <div v-show="!(journey.nights <= 0)" class="nights">
          {{ journey.nights }} {{ journey.nights === 1 ? 'Nuit' : 'Nuits' }}
        </div>
      </div>
      <div v-if="placeNames" class="locations-container">
        <Icon name="map-pin" width="9px" height="11px" />
        <div class="locations">{{ placeNames }}</div>
      </div>
    </NuxtLink>
    <NuxtLink
      :href="`/${journey.continents?.[0]?.slug}/${journey.countries?.[0]?.slug}/tours/${journey.slug}/`"
    >
      <div :class="isHovering ? 'see-more-up' : 'see-more-down'">En savoir plus</div></NuxtLink
    >
  </div>
</template>

<script lang="ts" setup>
import { AlgoliaJourney } from '@/lib/algolia-types/AlgoliaJourney';

const { journey } = defineProps<{
  journey: AlgoliaJourney;
}>();

// const like = $ref(false);
const isHovering = $ref(false);

const placeNames = journey.places?.map(place => place.label).join(' - ');
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/text-ellipsis.scss';

.journey-card {
  $card-width: 248px;
  $bottom-height: 54px;
  $card-mobile-width: 280px;
  $card-mobile-height: 375px;

  position: relative;

  overflow: hidden;
  flex-direction: column;
  flex-shrink: 0;

  width: $card-width;
  height: 280px;

  color: white;

  background-size: cover;
  border-radius: 8px;

  @include breakpoints.mobile() {
    width: $card-mobile-width;
    height: $card-mobile-height;
  }

  .inner {
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;

    padding: 11px 16px;

    background: linear-gradient(
      180deg,
      rgb(0 0 0 / 0%) 36.32%,
      rgb(0 0 0 / 45%) 71.32%,
      rgb(0 0 0 / 90%) 121.93%
    );

    .top {
      overflow: auto;

      .left {
        flex: 1;
        gap: 6px;
        align-items: center;
      }

      /*  .right {
        cursor: pointer;
        margin-left: 6px;

        @include breakpoints.mobile() {
          &:deep(svg) {
            width: 28px;
            height: 28px;
          }
        }

        &:deep(svg) {
          width: 22px;
          height: 20px;
        }
      } */
    }

    .infos {
      flex-direction: column;
      gap: 4px;

      .title {
        @include text-ellipsis.multi-line(2);

        font-size: 24px;
        font-weight: 900;
        line-height: 29px;
        color: white;
      }

      .price {
        flex-direction: column;
        gap: -2px;
        font-weight: 900;
        color: colors.$primary-yellow;

        .label {
          font-size: 12px;
          line-height: 14px;
        }

        .value {
          font-size: 24px;
          line-height: 29px;
        }
      }
    }
  }

  .bottom {
    flex-direction: column;
    gap: 2px;

    height: $bottom-height;
    padding: 10px 16px;

    background-color: colors.$primary-black;

    .durations {
      gap: 8px;
      align-items: center;

      font-size: 12px;
      font-weight: 700;
      line-height: 14px;

      /* .co2 {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        color: colors.$primary-yellow;
      } */

      .days {
        font-size: 12px;
        font-weight: bolder;
      }

      .days,
      .nights {
        color: white;
      }

      .point {
        $size: 2px;

        width: $size;
        height: $size;
        background-color: white;
        border-radius: 50%;
      }
    }

    .locations-container {
      gap: 6px;
      align-items: center;
      width: 90%;
      margin-top: 2px;

      .locations {
        @include text-ellipsis.single-line();

        font-size: 12px;
        font-weight: 500;
        line-height: 17px;
        color: white;
      }
    }
  }

  .see-more-up,
  .see-more-down {
    cursor: pointer;

    position: absolute;

    gap: 6px;
    justify-content: center;

    width: $card-width;
    height: $bottom-height;
    padding: 12px 16px;

    font-size: 16px;
    font-weight: 600;
    color: colors.$primary-black;

    background-color: colors.$primary-yellow;
    border-radius: 0 0 8px 8px;

    @include breakpoints.mobile() {
      display: none;
    }
  }

  .see-more-down {
    bottom: -$bottom-height;
    transition: all 0.25s ease-in-out;

    @include breakpoints.mobile() {
      display: none;
    }
  }

  .see-more-up {
    bottom: 0;
    transition: all 0.25s ease-in-out;

    @include breakpoints.mobile() {
      display: none;
    }
  }
}
</style>
