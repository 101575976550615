<template>
  <div v-if="journeys.length > 0" class="flex-column">
    <div class="flex-row">
      <div class="flex-column">
        <h1>Itinéraires</h1>
        <p v-if="numberOfResults" class="results-number">
          {{ `${numberOfResults} résultat${numberOfResults > 1 ? 's' : ''}` }}
        </p>
      </div>
      <NuxtLink
        class="more"
        :to="`/tous-les-itineraires/?search=${search}`"
        @click="onJourneyClick()"
        >Tous les itinéraires</NuxtLink
      >
    </div>
    <div class="flex-row">
      <SwiperArrows v-model="swiper" size="small" which="left" />
      <SwiperWrapper
        v-model="swiper"
        :initial-slide="0"
        class="inspirations"
        slides-per-view="auto"
      >
        <SwiperSlide v-for="journey of journeys" :key="journey.objectID">
          <GlobalSearchJourneyCard
            :key="journey.objectID"
            class="result-item"
            :journey="journey"
            @click="onJourneyClick()"
          />
        </SwiperSlide>
      </SwiperWrapper>
      <SwiperArrows v-model="swiper" size="small" which="right" />
    </div>
  </div>
  <div v-else-if="numberOfResults === 0" class="result-item">
    <h3>Aucun itinéraire trouvé</h3>
  </div>
</template>

<script lang="ts" setup>
import { AlgoliaJourney } from '@/lib/algolia-types/AlgoliaJourney';
import { SwiperBinding } from '@/lib/types/swiper';

defineProps<{
  journeys: AlgoliaJourney[];
  numberOfResults: number;
  search: string;
}>();
const emit = defineEmits<{
  (eventSelectedJourney: 'journeyClick'): void;
}>();

const swiper = ref<SwiperBinding>();

function onJourneyClick() {
  emit('journeyClick');
}
</script>

<style lang="scss" scoped>
@use '$/text-ellipsis.scss';
@use '$/colors.scss';
@use '$/misc.scss';

.flex-column {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  .results-number {
    color: colors.$grey-700;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.result-item {
  margin: 10px;
}

.more {
  @include misc.bold-link();

  justify-content: right;
  padding-top: 10px;
  text-align: right;
}
</style>
