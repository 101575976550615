<template>
  <div v-if="countries?.length > 0" class="flex-column">
    <div>
      <SwiperArrows v-model="swiper" size="small" which="left" />
      <SwiperWrapper v-model="swiper" :initial-slide="0" slides-per-view="auto">
        <SwiperSlide v-for="country of countries" :key="country.objectID">
          <NuxtLink
            :id="'tag' + country.objectID"
            :key="country.objectID"
            class="tag"
            :to="`/${country.continents?.[0]?.slug}/${country.slug}/`"
            @click="onCountryClick()"
          >
            {{ country.label }}
          </NuxtLink>
        </SwiperSlide>
      </SwiperWrapper>
      <SwiperArrows v-model="swiper" size="small" which="right" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { AlgoliaCountry } from '@/lib/algolia-types/AlgoliaCountry';
import { SwiperBinding } from '@/lib/types/swiper';

defineProps<{
  countries: AlgoliaCountry[];
}>();

const emit = defineEmits<{
  (eventSelectedCountry: 'articleClick'): void;
}>();

const swiper = ref<SwiperBinding>();

function onCountryClick() {
  emit('articleClick');
}
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/button.scss';

.flex-column {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.tag {
  @include button.outlined();

  margin-right: 48px;
  background-color: white;

  @include breakpoints.mobile() {
    margin: 0 16px 16px 0;
  }
}
</style>
