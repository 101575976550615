<template>
  <div class="input-container" :class="{ small, active: displayResults }">
    <div class="input-wrapper">
      <input
        v-model="textInput"
        class="input"
        type="text"
        :placeholder="placeholderText"
        @input="event => debouncedHandleInputChange((event.target as HTMLInputElement).value)"
        @click="
          () => {
            if (textInputLength >= NUMBER_OF_CHARACTERS_BEFORE_SEARCH) displayResults = true;
          }
        "
      />
      <div class="icon">
        <Icon
          v-if="displayResults"
          class="icon-close"
          name="close"
          size="25px"
          @click="displayResults = false"
        />
        <Icon v-if="!displayResults" class="icon-search" name="search" size="23px" />
      </div>
    </div>
  </div>
  <div v-if="displayResults" ref="modal" class="results-container" :class="{ small }">
    <div class="flex-column">
      <GlobalSearchCountries
        v-if="countriesHitsNumber !== null && countriesHitsNumber > 0"
        :countries="countries"
        @country-click="displayResults = false"
      />
      <div v-if="journeyHitsNumber === null || articleHitsNumber === null" class="loader" />
      <div v-else class="flex-column">
        <GlobalSearchJourneys
          :journeys="journeys"
          :number-of-results="journeyHitsNumber"
          :search="textInput"
          @journey-click="displayResults = false"
        />
        <div class="line-separator" />
        <GlobalSearchArticles
          :articles="articles"
          :number-of-results="articleHitsNumber"
          @article-click="displayResults = false"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside } from '@vueuse/core';

import { AlgoliaArticle } from '@/lib/algolia-types/AlgoliaArticle';
import { AlgoliaCountry } from '@/lib/algolia-types/AlgoliaCountry';
import { AlgoliaJourney } from '@/lib/algolia-types/AlgoliaJourney';
import debounce from 'lodash/debounce';

const { small = false } = defineProps<{
  placeholderText: string;
  small?: boolean;
}>();

const NUMBER_OF_CHARACTERS_BEFORE_SEARCH = 3;

const textInput = ref('');
const displayResults = ref(false);

const modal = ref(null);

onClickOutside(modal, () => {
  displayResults.value = false;
});

const textInputLength = computed(() => textInput.value.length);

const articles = ref<AlgoliaArticle[]>([]);
const articleHitsNumber = ref<null | number>(null);
const { result: articlesResult, search: articleSearch } =
  useAlgoliaSearch<AlgoliaArticle>('article');

const journeys = ref<AlgoliaJourney[]>([]);
const journeyHitsNumber = ref<null | number>(null);
const { result: journeysResult, search: journeySearch } =
  useAlgoliaSearch<AlgoliaJourney>('journey');

const countries = ref<AlgoliaCountry[]>([]);
const countriesHitsNumber = ref<null | number>(null);
const { result: countriesResult, search: countrySearch } =
  useAlgoliaSearch<AlgoliaCountry>('country');

function handleInputChange(value: string): void {
  if (value.length < NUMBER_OF_CHARACTERS_BEFORE_SEARCH) {
    return;
  }
  articleSearch({
    query: value
  });
  journeySearch({
    query: value
  });
  countrySearch({
    query: value
  });
}

const debouncedHandleInputChange = debounce(handleInputChange, 300);

watch(textInputLength, value => {
  if (value < NUMBER_OF_CHARACTERS_BEFORE_SEARCH) {
    displayResults.value = false;
  } else {
    displayResults.value = true;
  }
});

watch(articlesResult, value => {
  articles.value = value.hits as AlgoliaArticle[];
  articleHitsNumber.value = value.nbHits;
});

watch(journeysResult, value => {
  journeys.value = value.hits as AlgoliaJourney[];
  journeyHitsNumber.value = value.nbHits;
});

watch(countriesResult, value => {
  countries.value = value.hits as AlgoliaCountry[];
  countriesHitsNumber.value = value.nbHits;
});
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '$/misc.scss';
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/metrics.scss';
@use '$/mouse.scss';
@use '$/shadows.scss';
@use '$/text-ellipsis.scss';

.flex-column {
  display: flex;
  flex-direction: column;
}

.line-separator {
  width: 100%;
  height: 1px;
  margin: 10px;
  background-color: colors.$grey-700;
}

.input-container {
  $mobile-height: 56px;
  $height: 72px;

  position: relative;
  z-index: 1000;

  overflow: hidden;
  display: flex;
  align-items: center;

  width: 100%;
  height: $height;

  background-color: white;
  border-radius: math.div($height, 2);
  box-shadow: shadows.$page;

  transition: all 0.7s ease-in-out;

  @include breakpoints.mobile() {
    height: $mobile-height;
    border-radius: math.div($mobile-height, 2);
  }

  &.small {
    width: 60px;
    height: 50px;

    @include breakpoints.mobile() {
      width: 60px;
      height: 40px;
      border-radius: math.div(40px, 2);
    }

    &:hover,
    &.active {
      width: 100%;
      transition: all 0.7s ease-in-out;

      @include breakpoints.mobile() {
        width: 60px;
      }
    }
  }

  .input {
    width: 100%;
    height: 100%;
    padding-left: 20px;

    font-size: 18px;
    line-height: 22px;

    @include breakpoints.mobile() {
      font-size: 16px;
      line-height: 24px;
    }

    &::placeholder {
      color: colors.$grey-700;
      opacity: 1;
    }
  }

  .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
    width: 100%;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 60px;
      height: 50px;
      padding: 5px 10px;

      .icon-close {
        cursor: pointer;
      }
    }
  }
}

.results-container {
  position: absolute;
  z-index: 10;
  left: 10%;

  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  width: 70%;
  min-height: 300px;
  margin-top: -30px;
  padding: 110px 20px 20px;

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%);

  @include breakpoints.mobile() {
    left: 0;
    width: 100%;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.small {
    top: -10px;
    right: -10px;
    left: auto;

    width: 80vw;
    margin-top: 0;

    border-radius: 8px;
  }
}

.loader {
  @include misc.loader();

  align-self: center;
  width: 32px;
  height: 32px;
  margin: 16px;
}
</style>
